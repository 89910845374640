import React, { useState, useCallback } from 'react'
import {
    Divider,
    Grid,
    List,
    ListItemIcon,
    Popover,
} from '@mui/material'
import { BLACK, GREY } from '../../ui/Colors'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import {
    type Env,
    EnvStatus,
    EnvType,
    EnvName,
    KaryonModules,
} from '../../../redux/user/userTypes'
import {
    resetDashboard,
    setGlobalEnv,
} from '../../../redux/global/globalSlice'
import { useNavigate } from 'react-router-dom'
import { PrimaryText, SubTitle } from '../../ui/Text'
import { distributorImages } from '../../../helpers/distributorsList'
import {
    resetFSFilters,
    resetRetailFilters,
    setFsFilters,
    setRetailFilters,
} from '../../../redux/filters/filtersSlice'
import donutIcon from '../../../assets/icon/donutIcon.svg'
import { resetCampaigns, resetViews } from '../../../redux/views/viewsSlice'
import { GhostEnvironnements } from './GhostEnvironnements'
import SolarImport from '../../../assets/solar_import-linear.svg'
import { resetPromoImpact } from '../../../redux/promotions/promotionsSlice'
import { RenderSection } from './RenderSection'
import { getModulesNames, ModulesFrenchNames } from '../../../pages/private/Global/AdminPage/InvitationEnvStep'

interface EnvSelectorProps {
    open: boolean
    anchorEl: Element | null
    onClose: (e: React.MouseEvent) => void
    env: Env[]
    userModules: KaryonModules[]
    companyModules: KaryonModules[]
}

interface ModuleStatus {
    name: string;
    isActivated: boolean;
}

const allAvailableFSEnv = [EnvName.PASSIONFROID, EnvName.FRANCE_FRAIS, EnvName.METRO, EnvName.TRANSGOURMET, EnvName.SODEXO, EnvName.EPISAVEURS, EnvName.CERCLEVERT, EnvName.GBH, EnvName.DISTRIBOISSONS, EnvName.C10]
const allAvailableRetailEnv = [EnvName.INTERMARCHE, EnvName.CARREFOUR]
const allAvailableFSModules = [KaryonModules.CROSS, KaryonModules.PROMO]
const allAvailableRetailModules = [KaryonModules.CROSS]
const envFSWithPromoModule = [EnvName.TRANSGOURMET, EnvName.FRANCE_FRAIS]
const envRetailWithPromoModule = []

const getAllModulesStatus = (type: EnvType, userModules: KaryonModules[], companyModules: KaryonModules[], userEnvs: Env[]): ModuleStatus[] => {
    const availableModules = type === EnvType.FOOD_SERVICE ? allAvailableFSModules : allAvailableRetailModules;
    const moduleNames = getModulesNames(availableModules);
    const result: ModuleStatus[] = [];

    const userEnvNames = userEnvs.map(env => env.name);
    const promoEnvs = type === EnvType.FOOD_SERVICE ? envFSWithPromoModule : envRetailWithPromoModule;

    availableModules.forEach((module, index) => {
        if (module === KaryonModules.PROMO) {
            if (!userModules.includes(KaryonModules.PROMO) && !companyModules.includes(KaryonModules.PROMO)) {
                // If Company And User doesn't have module Promo
                result.push({
                    name: "Promotions",
                    isActivated: false
                });
            } else {
                promoEnvs.forEach(env => {
                    const hasEnv = userEnvNames.includes(env);
                    if (userModules.includes(module) && hasEnv) {
                        result.push({
                            name: `Promo ${env}`,
                            isActivated: userModules.includes(module) && hasEnv
                        });
                    }
                });
            }
        } else if (module === KaryonModules.CROSS) {
            if (userModules.includes(KaryonModules.CROSS) && companyModules.includes(KaryonModules.CROSS)) {
                // If the company and the user has Cross, the user can access
                result.push({
                    name: moduleNames[index],
                    isActivated: true
                });

            } else if (!userModules.includes(KaryonModules.CROSS) && !companyModules.includes(KaryonModules.CROSS)) {
                // If the company and the user doesn't have the module cross, the user should  see the module and get contact Dialog
                result.push({
                    name: moduleNames[index],
                    isActivated: false
                });
            }
            // else (but no need to have condition to do it): if the company has the module cross but not the user, the user shouldn't see the module
        }
        else {
            result.push({
                name: moduleNames[index],
                isActivated: userModules.includes(module)
            });
        }
    });

    return result;
}

const SeparatorEnvNavigation = () => {
    return (
        <hr
            style={{
                width: '100%',
                height: '0.1vh',
                border: 'none',
                background: GREY,
                margin: 0
            }}
        />
    )
}

const EnvSelector = ({ open, anchorEl, onClose, env, userModules, companyModules }: EnvSelectorProps) => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const _setEnv = useCallback(
        (env: Env) => dispatch(setGlobalEnv(env)),
        [dispatch]
    )

    const _resetFsFilters = useCallback(
        () => dispatch(resetFSFilters()),
        [dispatch]
    )

    const _resetPromotions = useCallback(
        () => dispatch(resetPromoImpact()),
        [dispatch]
    )
    const envName = useAppSelector((state) => state.global.env.name)

    const _resetViews = useCallback(() => dispatch(resetViews()), [dispatch])
    const _resetCampaigns = useCallback(() => dispatch(resetCampaigns()), [dispatch])

    // Sort environnement by alphabetic name in the menu
    env.sort((a, b) => {
        return a.name > b.name ? 1 : a.name === b.name ? 0 : -1
    })

    const handleImportNavigation = (e: React.MouseEvent, env: Env) => {
        _resetRetailFilters()
        _resetFsFilters()
        _resetViews()
        _resetPromotions()
        _setEnv(env)
        _resetCampaigns()
        onClose(e)
        navigate('/parametres/import-retail')
    }

    const _resetFSFilters = useCallback(() => {
        dispatch(setFsFilters(null))
        dispatch(resetFSFilters())
        dispatch(resetDashboard())
    }, [dispatch])

    const _resetRetailFilters = useCallback(() => {
        dispatch(setRetailFilters(null))
        dispatch(resetRetailFilters())
        dispatch(resetDashboard())
    }, [dispatch])

    const handleEnvSelection = (e: React.MouseEvent, name: EnvName | string, type: EnvType, isModule: boolean, isActivated: boolean) => {
        _resetFSFilters()
        _resetRetailFilters()
        onClose(e)

        if (name === ModulesFrenchNames.CROSS && isActivated) {
            _setEnv({ name: type === EnvType.FOOD_SERVICE ? EnvName.FOOD_SERVICE_CROSS : EnvName.RETAIL_CROSS, type, status: EnvStatus.ACTIVATED })
            navigate(`/${type}/cross/dashboard`)
        } else if (name.includes('Promo') && isActivated) {
            const environmentName = name.replace('Promo ', '');
            _setEnv({ name: environmentName as EnvName, type, status: EnvStatus.ACTIVATED })
            navigate(`/${type}/${environmentName}/promo`)

        } else if (name.includes('Promo') && !isActivated) {
            navigate(`/${type}/${envName}/promoDemo/`)

        } else if (type === EnvType.FOOD_SERVICE && !isModule || type === EnvType.RETAIl && !isModule) {
            _setEnv({ name: name as EnvName, type, status: EnvStatus.ACTIVATED })
            navigate(`/${type}/${name}/dashboard`)
        }
    }

    //Create list of environnements : user environnements and available environnements
    const ghostEnvironnementsRetail: EnvName[] = allAvailableRetailEnv.filter((envName) => !env.some((e) => e.name === envName))
    const ghostEnvironnementsFS: EnvName[] = allAvailableFSEnv.filter((envName) => !env.some((e) => e.name === envName))
    const envRetail = env.filter((env) => env.type === EnvType.RETAIl)
    const envFS = env.filter((env) => env.type === EnvType.FOOD_SERVICE)

    const fsModulesStatus = getAllModulesStatus(EnvType.FOOD_SERVICE, userModules, companyModules, env);
    const retailModulesStatus = getAllModulesStatus(EnvType.RETAIl, userModules, companyModules, env);


    return (
        <Popover
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
        >
            <List dense sx={{ padding: 0, minWidth: '15vw' }}>
                {envFS.length > 0 && <Grid>
                    <Grid>
                        <PrimaryText
                            style={{
                                color: BLACK,
                                padding: '1vh',
                                fontWeight: 'bold',
                                margin: '1vh',

                            }}
                        >
                            FOODSERVICE
                        </PrimaryText>
                        <RenderSection
                            title="Modules"
                            handleEnvSelection={handleEnvSelection}
                            items={fsModulesStatus}
                            type={EnvType.FOOD_SERVICE}
                            isModule={true}
                        />
                        <RenderSection
                            title="Espaces distributeurs"
                            isModule={false}
                            handleEnvSelection={handleEnvSelection}
                            items={envFS}
                            type={EnvType.FOOD_SERVICE} />
                    </Grid>
                    {ghostEnvironnementsFS.length > 0 && <Grid id="ghost-distributors">
                        <GhostEnvironnements env={ghostEnvironnementsFS} />
                    </Grid>}
                </Grid>}
                <Divider />
                {envRetail.length > 0 &&
                    <Grid>
                        <Grid container>
                            <PrimaryText
                                style={{
                                    color: BLACK,
                                    padding: '1vh',
                                    fontWeight: 'bold',
                                    margin: '1vh',

                                }}
                            >
                                RETAIL
                            </PrimaryText>
                            <img
                                style={{
                                    cursor: "pointer",
                                    width: "2vw"
                                }}
                                onClick={(e) => {
                                    handleImportNavigation(e, {
                                        name: EnvName.MYDISTRIB,
                                        type: EnvType.SETTING,
                                        status: EnvStatus.ACTIVATED,
                                    })
                                }}
                                alt="Importer"
                                src={SolarImport}
                            />
                        </Grid>
                        <Grid>
                            <RenderSection
                                title="Modules"
                                handleEnvSelection={handleEnvSelection}
                                items={retailModulesStatus}
                                type={EnvType.RETAIl}
                                isModule={true}
                            />
                            <RenderSection
                                title="Espaces distributeurs"
                                isModule={false}
                                handleEnvSelection={handleEnvSelection}
                                items={envRetail}
                                type={EnvType.RETAIl} />
                        </Grid>

                        {ghostEnvironnementsRetail.length > 0 && <Grid>
                            <GhostEnvironnements env={ghostEnvironnementsRetail} />
                        </Grid>}
                    </Grid>
                }
            </List>
        </Popover >
    )
}

const MultipleEnv = ({
    name,
    type,
    handler,
}: {
    name: string
    type: EnvType
    handler: (e) => void
}) => {
    return (
        <Grid
            p="2vh 0"
            id="env-selector"
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            sx={{ cursor: 'pointer' }}
            onClick={(e) => {
                handler(e)
            }}
        >
            {[
                EnvType.SETTING,
                EnvType.FOOD_SERVICE_CROSS,
                EnvType.RETAIL_CROSS,
            ].includes(type) ? (
                <ListItemIcon
                    sx={{
                        justifyContent: 'center',
                        minWidth: '3vw',
                    }}
                >
                    <img src={donutIcon} width="40%" />
                </ListItemIcon>
            ) : (
                <ListItemIcon
                    sx={{
                        justifyContent: 'center',
                        minHeight: '2.5vh',
                        width: '2.5vw',
                        height: '1vh',
                    }}
                >
                    <img
                        alt="Logo du distributeur"
                        src={distributorImages[name]}
                    />
                </ListItemIcon>
            )}
            <PrimaryText
                color={BLACK}
                noWrap
                textOverflow="ellipsis"
                pl="0.5vw"
                style={{ cursor: "pointer" }}            >
                {name}
            </PrimaryText>
            <ListItemIcon
                sx={{
                    justifyContent: 'flex-end',
                    minWidth: '1vw',
                    '& svg': {
                        width: '100%',
                        height: '2.5vh',
                    },
                }}
            >
                <UnfoldMoreIcon />
            </ListItemIcon>
        </Grid>
    )
}

const UniqueEnv = ({ name, type }: { name: string; type: EnvType }) => {
    return (
        <Grid container mb="2vh" mt="2vh" justifyContent="space-between">
            <SubTitle color={BLACK}>{name}</SubTitle>
            {type !== EnvType.SETTING ? (
                <ListItemIcon>
                    <img
                        style={{ width: '3vw', paddingRight: '0.5vw' }}
                        alt="Logo du distributeur"
                        src={distributorImages[name]}
                    />
                </ListItemIcon>
            ) : (
                <img src={donutIcon} />
            )}
        </Grid>
    )
}

const EnvNavigation = () => {
    const [open, setOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const { env, modules, companyModules } = useAppSelector((state) => state.user.user)
    const currentEnv = useAppSelector((state) => state.global.env)

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault()

        if (open) {
            setAnchorEl(null)
            setOpen(false)
        } else {
            setAnchorEl(e.currentTarget)
            setOpen(true)
        }
    }

    return (
        <Grid container direction="column" mb="4vh" justifyContent="center">
            <SeparatorEnvNavigation />
            {env.length > 1 ? (
                <MultipleEnv
                    type={currentEnv.type}
                    name={currentEnv.name}
                    handler={handleClick}
                />
            ) : (
                <UniqueEnv type={currentEnv.type} name={currentEnv.name} />
            )}
            <SeparatorEnvNavigation />
            <EnvSelector
                open={open}
                anchorEl={anchorEl}
                onClose={(e: React.MouseEvent) => {
                    handleClick(e)
                }}
                env={env.filter((env) => env.type !== EnvType.SETTING)}
                userModules={modules}
                companyModules={companyModules}
            />
        </Grid>
    )
}

export default EnvNavigation
