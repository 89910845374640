import React from 'react'
import { routerType } from './PublicNavigation'
import SetNewPassword from '../../../../pages/public/authentication/SetNewPassword'
import { ProfilPage } from '../../../../pages/private/Global/ProfilPage/ProfilPage'
import { AdminPage } from '../../../../pages/private/Global/AdminPage/AdminPage'
import ImportPage from '../../../../pages/private/Global/Import/ImportPage'

export const settingsPages: routerType[] = [
    {
        path: 'import',
        element: <ImportPage />,
        title: 'import',
    },
    {
        path: 'profil',
        element: <ProfilPage />,
        title: 'profil',
    }, {
        path: 'administration',
        element: <AdminPage />,
        title: 'Administration',
    },
    {
        path: 'setnewpassword',
        element: <SetNewPassword />,
        title: 'setnewpassword',
    },
]
