import * as React from 'react'
import {
    type GridColDef,
    type GridColumnHeaderParams,
    type GridRenderCellParams,
} from '@mui/x-data-grid'
import { Grid } from '@mui/material'
import { PrimaryText, SecondaryText } from '../../../components/ui/Text'
import { BLACK } from '../../../components/ui/Colors'
import TendencyDashboard from '../../../components/features/foodService/dashboard/TendencyDashboard'
import NumberWithSpace from '../../../components/ui/NumberWithSpace'
import Tendency, { TooltipMessage } from '../../../components/ui/Tendency'
import { Units } from '../../../redux/global/globalTypes'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import { roundIfNotZero } from '../../../helpers/roundIfNotZero'
import { useAppSelector } from '../../../redux/hooks'
import { CellTooltip, ColumnHeaderTextTooltip } from '../../../components/features/drillDowns/setupDrillDown'

export const SwitchText = ({ title, color, bold, ml = 0, length, isHeader = false }: { title: string, color: string, length: number, children: string, isHeader?: boolean, bold?: boolean, ml?: number }) => {
    switch (isHeader) {
        case true:
            return <ColumnHeaderTextTooltip tooltipIsNeeded={length > 10} text={title} />
        case false:
            return <CellTooltip text={title} bold={bold} ml={ml} color={color} />
        default:
            return <CellTooltip text={title} bold={bold} ml={ml} color={color} />
    }
}

export const FFRColumnsCustomersPage = (): GridColDef[] => {
    return [
        {
            field: 'type',
            headerName: `Sous-sous-types d'utilisateurs`,
            editable: false,
            flex: 2.5,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SwitchText color={BLACK} length={params.value.length} title={params.value}>
                        {params.value ? params.value : '-'}
                    </SwitchText >
                )
            },
        },
        {
            field: 'clients',
            headerName: 'Clients',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceClients',
            headerName: `Différence de clients`,
            flex: 0.8,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceClients}
                        unit={Units.none}
                        tooltip={TooltipMessage.CUSTOMERS}
                    />
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'warehouses',
            headerName: 'Dépôts',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de dépôts`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWarehouses}
                        unit={Units.none}
                        tooltip={TooltipMessage.WAREHOUSES}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWeight}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kgPerWarehouse})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: 'Différence de VMM',
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceVMM}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${Units.kgPerWarehouse})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const TGMPlusColumnsCustomersPage = (): GridColDef[] => {
    return [
        {
            field: 'type',
            headerName: `Sous-types d'utilisateurs`,
            editable: false,
            flex: 2.5,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SwitchText color={BLACK} length={params.value.length} title={params.value}>
                        {params.value ? params.value : '-'}
                    </SwitchText >
                )
            },
        },
        {
            field: 'clients',
            headerName: 'Clients',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceClients',
            headerName: `Différence de clients`,
            flex: 0.8,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceClients}
                        unit={Units.none}
                        tooltip={TooltipMessage.CUSTOMERS}
                    />
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'warehouses',
            headerName: 'Dépôts',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de dépôts`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWarehouses}
                        unit={Units.none}
                        tooltip={TooltipMessage.WAREHOUSES}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWeight}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kgPerWarehouse})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: 'Différence de VMM',
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceVMM}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
    ]
}

export const CercleVertColumnsCustomersPage = (): GridColDef[] => {
    return [
        {
            field: 'type',
            headerName: `Sous-sous-types d'utilisateurs`,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        {params.value ? params.value : '-'}
                    </SecondaryText>
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWeight}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceVMM}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const SodexoColumnsCustomersPage = (unit: Units): GridColDef[] => {
    return [
        {
            field: 'type',
            headerName: 'Type de clients',
            editable: false,
            flex: 2.5,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={unit}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${unit})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWeight}
                        unit={unit}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${unit})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${unit})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWeight}
                        unit={unit}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${unit})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const TransgourmetColumnsCustomersPage = (): GridColDef[] => {
    return [
        {
            field: 'id',
            headerName: 'Type de clients',
            editable: false,
            flex: 2.5,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        {params.value ? params.value : '-'}
                    </SecondaryText>
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWeight}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const EpiSaveursColumnsCustomersPage = (): GridColDef[] => {
    return [
        {
            field: 'type',
            headerName: `Types d'utilisateurs`,
            editable: false,
            flex: 2.5,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        {params.value ? params.value : '-'}
                    </SecondaryText>
                )
            },
        },
        {
            field: 'warehouses',
            headerName: 'Dépôts',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de dépôts`,
            flex: 0.8,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.WAREHOUSES}
                    />
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: 'Différence de références',
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kgPerWarehouse})`,
            flex: 1.2,
            editable: false,
            /*    hide: hidden, */
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            /*      hide: hidden, */
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${Units.kgPerWarehouse})`,
            flex: 1.2,
            /* hide: hidden, */
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}
export const PassionFroidColumnsCustomersPage = (): GridColDef[] => {
    return [
        {
            field: 'type',
            headerName: `Sous-sous-types d'utilisateurs`,
            editable: false,
            flex: 2.5,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        {params.value ? params.value : '-'}
                    </SecondaryText>
                )
            },
        },
        {
            field: 'warehouses',
            headerName: 'Dépôts',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de dépôts`,
            flex: 0.8,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.WAREHOUSES}
                    />
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}
export const FFRColumnsDashboard = (unit: Units): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Nom',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'differenceWeight',
            headerName: "Croissance",
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center">
                        <TendencyDashboard
                            variation={Math.round(params.row.differenceWeight)}
                            unit={unit}
                        />
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'volume',
            headerName: `Période A`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />{' '}
                            {unit}
                        </SecondaryText>
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'volumeLastYear',
            headerName: `Période A - 1`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                if (!params.value) return <PrimaryText>-</PrimaryText>

                return (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />
                            {unit}
                        </SecondaryText>
                    </Grid>
                )
            },
            sortable: false,
        },
    ]
}

export const C10AndDistriboissonsColumnsDashboard = (unit: Units): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Nom',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'differenceVolume',
            headerName: "Croissance",
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center">
                        <TendencyDashboard
                            variation={Math.round(params.row.differenceVolume)}
                            unit={unit}
                        />
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'volume',
            headerName: `Période A`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />{' '}
                            {unit}
                        </SecondaryText>
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'volumeLastYear',
            headerName: `Période A - 1`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                if (!params.value) return <PrimaryText>-</PrimaryText>

                return (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />
                            {unit}
                        </SecondaryText>
                    </Grid>
                )
            },
            sortable: false,
        },
    ]
}


export const GBHColumnsDashboard = (): GridColDef[] => {
    return [
        {
            field: 'id',
            headerName: 'Fournisseurs',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
            sortable: false,
        },

        {
            field: 'volume',
            headerName: `Volume A (${Units.uvc})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />{' '}
                        </SecondaryText>
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'differenceWeight',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center">
                        <TendencyDashboard
                            variation={Math.round(params.row.differenceWeight)}
                            unit={Units.uvc}
                        />
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'shareVolume',
            headerName: `PDM A (${Units.uvc})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                if (!params.value) return <PrimaryText>-</PrimaryText>

                return (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={`${Math.round(params.value)} ${Units.percent}`}
                            />
                        </SecondaryText>
                    </Grid>
                )
            },
            sortable: false,
        },
        {
            field: 'differenceShareVolume',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return Math.round(params.value) ? (
                    <Grid container alignItems="center">
                        <TendencyDashboard
                            variation={Math.round(params.value)}
                            unit={Units.none}
                        />
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
    ]
}


export const GBHRegionsColumnsDashboard = (): GridColDef[] => {
    return [
        {
            field: 'id',
            headerName: 'Territoires',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
            sortable: false,
        },

        {
            field: 'volume',
            headerName: `Volume A(${Units.uvc})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />
                        </SecondaryText>
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'differenceWeight',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center">
                        <TendencyDashboard
                            variation={Math.round(params.row.differenceWeight)}
                            unit={Units.uvc}
                        />
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'shareVolume',
            headerName: `PDM A (${Units.uvc})`,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                if (!params.value) return <PrimaryText>-</PrimaryText>

                return (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={`${Math.round(params.value)} ${Units.percent}`}
                            />
                        </SecondaryText>
                    </Grid>
                )
            },
            sortable: false,
        },
        {
            field: 'differenceShareVolume',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                if (!params.value) return <PrimaryText>-</PrimaryText>
                const number = params.value
                return (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={number.toFixed(2)}
                            />
                        </SecondaryText>
                    </Grid>
                )
            },
            sortable: false,
        },
    ]
}
export const SodexoColumnsDashboard = (unit: Units): GridColDef[] => {
    return [
        {
            field: 'id',
            headerName: '',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'differenceWeight',
            headerName: `Croissance`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center">
                        <TendencyDashboard
                            variation={Math.round(params.row.differenceWeight)}
                            unit={unit}
                        />
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'volume',
            headerName: `Période A`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />{' '}
                            {unit}
                        </SecondaryText>
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'volumeLastYear',
            headerName: `Période A - 1`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                if (!params.value) return <PrimaryText>-</PrimaryText>

                return (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />
                            {unit}
                        </SecondaryText>
                    </Grid>
                )
            },
            sortable: false,
        },
    ]
}

export const EpiSaveursAndPassionFroidColumnsDashboard = (
    unit: string
): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Nom',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'differenceWeight',
            headerName: `Croissance`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center">
                        <TendencyDashboard
                            variation={Math.round(params.row.differenceWeight)}
                            unit={Units.kg}
                        />
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'volume',
            headerName: `Période A`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />{' '}
                            {unit}
                        </SecondaryText>
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'volumeLastYear',
            headerName: `Période A - 1`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                if (!params.value) return <PrimaryText>-</PrimaryText>

                return (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />
                            {unit}
                        </SecondaryText>
                    </Grid>
                )
            },
            sortable: false,
        },
    ]
}
export const TransgourmetColumnsDashboard = (unit: string): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Nom',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'ca',
            headerName: `Part de CA`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />
                            {Units.percent}
                        </SecondaryText>
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
    ]
}

export const MetroColumnsCustomersPage = (): GridColDef[] => {
    return [
        {
            field: 'type',
            headerName: `Types d'utilisateurs`,
            editable: false,
            flex: 2.5,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        {params.value ? params.value : '-'}
                    </SecondaryText>
                )
            },
        },
        {
            field: 'ca',
            headerName: `CA en A (${Units.euro})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceCa',
            headerName: `Différence de CA`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.euro}
                        tooltip={TooltipMessage.CA}
                    />
                )
            },
        },
        {
            field: 'caLastYear',
            headerName: `CA en A-1 (${Units.euro})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const C10AndDistriboissonsColumnsWarehousesPage = (): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Dépôts',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.liter})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVolume',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'vmmVolume',
            headerName: `VMM (${Units.literPerMonth})`,
            flex: 1.2,
            editable: false,
            hide: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVmmVolume',
            headerName: `Différence de VMM`,
            flex: 0.8,
            hide: false,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
        {
            field: 'unitVolume',
            headerName: `Volume A (${Units.uvc})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceUnitVolume',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'vmmUnitVolume',
            headerName: `VMM (${Units.uvcPerMonth})`,
            flex: 1.2,
            editable: false,
            hide: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVmmUnitVolume',
            headerName: `Différence de VMM`,
            flex: 0.8,
            hide: false,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },

    ]
}

export const C10AndDistriboissonsColumnsRegionsPage = (): GridColDef[] => {
    return [
        {
            field: 'region',
            headerName: 'Région commerciale',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'warehouses',
            headerName: 'Dépôts',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de dépôts`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.liter})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVolume',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'vmmVolume',
            headerName: `VMM (${Units.literPerMonth})`,
            flex: 1.2,
            editable: false,
            hide: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVmmVolume',
            headerName: `Différence de VMM`,
            flex: 0.8,
            hide: false,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
        {
            field: 'unitVolume',
            headerName: `Volume A (${Units.uvc})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceUnitVolume',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'vmmUnitVolume',
            headerName: `VMM (${Units.uvcPerMonth})`,
            flex: 1.2,
            editable: false,
            hide: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVmmUnitVolume',
            headerName: `Différence de VMM`,
            flex: 0.8,
            hide: false,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
    ]
}

export const FFRColumnsWarehousesPage = (): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Dépôts',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'clients',
            headerName: 'Clients',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceClients',
            headerName: `Différence de clients`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceClients}
                        unit={Units.none}
                        tooltip={TooltipMessage.CUSTOMERS}
                    />
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kg})`,
            flex: 1.2,
            editable: false,
            hide: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            hide: false,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${Units.kg})`,
            flex: 1.2,
            hide: false,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const TGMPlusColumnsWarehousesPage = (): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Dépôts',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'clients',
            headerName: 'Clients',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceClients',
            headerName: `Différence de Transactions`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceClients}
                        unit={Units.none}
                        tooltip={TooltipMessage.CUSTOMERS}
                    />
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kg})`,
            flex: 1.2,
            editable: false,
            hide: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            hide: false,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
    ]
}


export const MetroColumnsWarehousesPage = (unit: Units): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Dépôts',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${unit})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${unit})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${unit})`,
            flex: 1.2,
            editable: false,
            hide: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            hide: false,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${unit})`,
            flex: 1.2,
            hide: false,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const EpiSaveursColumnsWarehousesPage = (): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Dépôts',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWeight}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kg})`,
            flex: 1.2,
            editable: false,
            /*    hide: hidden, */
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            /*      hide: hidden, */
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${Units.kg})`,
            flex: 1.2,
            /* hide: hidden, */
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}
export const PassionFroidColumnsWarehousesPage = (): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Dépôts',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceProducts}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWeight}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}
export const TransgourmetColumnsWarehousesPage = (): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Dépôts',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'clients',
            headerName: 'Transactions',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'ca',
            headerName: `Part de CA (%)`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'evolCa',
            headerName: `Evolution du CA (%)`,

            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const FFRColumnsProductsPage = (): GridColDef[] => {
    return [
        {
            field: 'productName',
            headerName: 'Références',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'warehouses',
            headerName: 'Dépôts',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de dépôts`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.WAREHOUSES}
                    />
                )
            },
        },
        {
            field: 'clients',
            headerName: 'Clients',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceClients',
            headerName: `Différence de clients`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.CUSTOMERS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kgPerWarehouse})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${Units.kgPerWarehouse})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const C10AndDistriboissonsColumnsProductsPage = (): GridColDef[] => {
    return [
        {
            field: 'productName',
            headerName: 'Références',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'warehouses',
            headerName: 'Dépôts',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de dépôts`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.WAREHOUSES}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.liter})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVolume',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'vmmVolume',
            headerName: `VMM (${Units.literPerMonth})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVmmVolume',
            headerName: `Différence de VMM`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
        {
            field: 'unitVolume',
            headerName: `Volume A (${Units.uvc})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceUnitVolume',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'vmmUnitVolume',
            headerName: `VMM (${Units.uvcPerMonth})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVmmUnitVolume',
            headerName: `Différence de VMM`,
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
    ]
}

export const TGMPlusColumnsProductsPage = (hidden: boolean): GridColDef[] => {
    return [
        {
            field: 'productName',
            headerName: 'Références',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'clients',
            headerName: 'Clients',
            flex: 1,
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {


                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceClients',
            headerName: `Différence de transactions`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.CUSTOMERS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {


                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kgPerWarehouse})`,
            flex: 1.2,
            editable: false,
            /*    hide: hidden, */
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            /*      hide: hidden, */
            editable: false,

            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
    ]
}
export const CercleVertColumnsProductsPage = (hidden: boolean): GridColDef[] => {
    return [
        {
            field: 'productName',
            headerName: 'Références',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },

            renderCell: (params: GridRenderCellParams) => {


                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },

            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },

            renderCell: (params: GridRenderCellParams) => {


                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}
export const SodexoColumnsProductsPage = (hidden: boolean): GridColDef[] => {
    return [
        {
            field: 'productName',
            headerName: 'Références',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kg})`,
            flex: 1.2,
            editable: false,
            /*    hide: hidden, */
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {

                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            /*      hide: hidden, */
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${Units.kg})`,
            flex: 1.2,
            /* hide: hidden, */
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const EpiSaveursColumnsProductsPage = (
    hidden: boolean
): GridColDef[] => {
    return [
        {
            field: 'productName',
            headerName: 'Références',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'warehouses',
            headerName: 'Dépôts',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de dépôts`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {

                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kgPerWarehouse})`,
            flex: 1.2,
            editable: false,
            /*    hide: hidden, */
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },

            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            /*      hide: hidden, */
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },

            renderCell: (params: GridRenderCellParams) => {

                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${Units.kgPerWarehouse})`,
            flex: 1.2,
            /* hide: hidden, */
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },

            renderCell: (params: GridRenderCellParams) => {


                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}
export const PassionFroidColumnsProductsPage = (
    hidden: boolean
): GridColDef[] => {
    return [
        {
            field: 'productName',
            headerName: 'Références',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SwitchText color={BLACK} length={params.value.length} title={params.value}>
                        {params.value}
                    </SwitchText>
                )
            },
        },
        {
            field: 'warehouses',
            headerName: 'Dépôts',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },

            renderCell: (params: GridRenderCellParams) => {


                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de dépôts`,
            flex: 1,
            editable: false,

            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.WAREHOUSES}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1,
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {


                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,

            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1,
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kg})`,
            flex: 1.2,
            editable: false,
            /*    hide: hidden, */

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            /*      hide: hidden, */
            editable: false,

            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${Units.kg})`,
            flex: 1.2,
            /* hide: hidden, */
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}
export const TGMColumnsProductsPage = (hidden: boolean): GridColDef[] => {
    return [
        {
            field: 'productName',
            headerName: 'Références',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SwitchText color={BLACK} length={params.value.length} title={params.value}>
                        {params.value}
                    </SwitchText>
                )
            },
        },
        {
            field: 'clients',
            headerName: 'Transactions',
            flex: 1,
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        {params.value !== '-' ? (
                            <NumberWithSpace
                                number={roundIfNotZero(params.value)}
                            />
                        ) : (
                            '-'
                        )}
                    </SecondaryText>
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.kg})`,
            flex: 1,
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },

            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 1,
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kg})`,
            flex: 1.2,
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            editable: false,

            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${Units.kg})`,
            flex: 1.2,
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const MetroColumnsProductsPage = (): GridColDef[] => {
    const { unit } = useAppSelector((state) => state.global)
    const unitVMM = unit === Units.uvc ? Units.uvcPerWarehouse : Units.kgPerWarehouse
    return [
        {
            field: 'productName',
            headerName: 'Références',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SwitchText color={BLACK} length={params.value.length} title={params.value}>
                        {params.value}
                    </SwitchText>
                )
            },
        },
        {
            field: 'warehouses',
            headerName: 'Dépôts',
            flex: 1,
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {


                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de dépôts`,
            flex: 1,
            editable: false,

            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.WAREHOUSES}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${unit})`,
            flex: 1,
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {


                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,

            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${unit})`,
            flex: 1,
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {


                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${unitVMM})`,
            flex: 1.2,
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 0.8,
            editable: false,

            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.VMM}
                    />
                )
            },
        },
        {
            field: 'VMMLastYear',
            headerName: `VMM A-1 (${unitVMM})`,
            flex: 1.2,
            /* hide: hidden, */
            editable: false,

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {


                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}


export const GBHProductsHeadTabColumns = (type: string): GridColDef[] => {
    return [
        ...(type === "brand" ? [{
            field: 'brand',
            headerName: 'Marques',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        }] : []),
        ...(type === "segment" ? [{
            field: 'segment',
            headerName: 'Segments',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        }] : []),
        {
            field: 'products',
            headerName: 'Références',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SwitchText color={BLACK} length={params.value.length} title={params.value}>
                        {params.value}
                    </SwitchText >
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de produits`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume (${Units.uvc})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVolume',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.uvc}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'ca',
            headerName: `CA (${Units.euro})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceCa',
            headerName: `Différence de CA`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.euro}
                        tooltip={TooltipMessage.CA}
                    />
                )
            },
        },
        {
            field: 'shareVolume',
            headerName: `PDM en volume`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceShareVolume',
            headerName: `Différence de PDM en volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PDM_VOLUME}
                    />
                )
            },
        },
        {
            field: 'shareCa',
            headerName: `PDM en valeur`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceShareCa',
            headerName: `Différence de PDM en valeur`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PDM_VALUE}
                    />
                )
            },
        },
    ]
}

export const GBHSuppliersHeadTabColumns = (type: string, companyName: string): GridColDef[] => {
    return [
        ...(type === "brand" ? [{
            field: 'brand',
            headerName: 'Marque',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        }] : []),
        ...(type === "segment" ? [{
            field: 'segment',
            headerName: 'Segment',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        }] : []),
        ...(type === "region" ? [{
            field: 'region',
            headerName: 'Territoires',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        }] : []),
        {
            field: 'suppliers',
            headerName: 'Fournisseurs',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'differenceSuppliers',
            headerName: `Différence de fournisseurs`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'warehouses',
            headerName: 'DN',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de DN`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'products',
            headerName: 'Références',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PRODUCTS}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume (${Units.uvc})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVolume',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.uvc}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'ca',
            headerName: `CA (${Units.euro})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceCa',
            headerName: `Différence de CA`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.euro}
                        tooltip={TooltipMessage.CA}
                    />
                )
            },
        },
        {
            field: 'shareVolume',
            headerName: `PDM ${companyName} en volume`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceShareVolume',
            headerName: `Différence de PDM en volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PDM_VOLUME}
                    />
                )
            },
        },
        {
            field: 'shareCa',
            headerName: `PDM ${companyName} en CA`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceShareCa',
            headerName: `Différence de PDM en CA`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PDM_VALUE}
                    />
                )
            },
        },
    ]
}

export const GBHProductsColumns = (type: string): GridColDef[] => {
    return [
        {
            field: 'product',
            headerName: 'Références',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        ...(type === "brand" ? [{
            field: 'brand',
            headerName: 'Marque',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        }] : []),
        ...(type === "segment" ? [{
            field: 'brand',
            headerName: 'Marque',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        }] : []),
        {
            field: 'warehouses',
            headerName: `DN`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de DN`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.DN}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume (${Units.uvc})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVolume',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.uvc}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'ca',
            headerName: `CA (${Units.euro})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceCa',
            headerName: `Différence de CA`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.euro}
                        tooltip={TooltipMessage.CA}
                    />
                )
            },
        },
        {
            field: 'shareVolume',
            headerName: `PDM en volume`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceShareVolume',
            headerName: `Différence de PDM en volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PDM_VOLUME}
                    />
                )
            },
        },
        {
            field: 'shareCa',
            headerName: `PDM en valeur`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceShareCa',
            headerName: `Différence de PDM en valeur`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PDM_VALUE}
                    />
                )
            },
        },
    ]
}

export const GBHSuppliersColumns = (type: string): GridColDef[] => {
    return [
        {
            field: 'supplier',
            headerName: 'Fournisseurs',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        ...(type === "brand" ? [{
            field: 'brand',
            headerName: 'Marque',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        }] : []),
        ...(type === "region" ? [{
            field: 'region',
            headerName: 'Territoires',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        }] : []),
        ...(type === "segment" ? [{
            field: 'segment',
            headerName: 'Segment',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        }] : []),
        {
            field: 'warehouses',
            headerName: `DN`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de DN`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.DN}
                    />
                )
            },
        },
        {
            field: 'products',
            headerName: `Références`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.DN}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume (${Units.uvc})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVolume',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.uvc}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'ca',
            headerName: `CA (${Units.euro})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceCa',
            headerName: `Différence de CA`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.euro}
                        tooltip={TooltipMessage.CA}
                    />
                )
            },
        },
        {
            field: 'shareVolume',
            headerName: `PDM en volume`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceShareVolume',
            headerName: `Différence de PDM en volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PDM_VOLUME}
                    />
                )
            },
        },
        {
            field: 'shareCa',
            headerName: `PDM en CA`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceShareCa',
            headerName: `Différence de PDM en CA`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PDM_VALUE}
                    />
                )
            },
        },
    ]
}

export const GBHWarehousesHeadTabColumns = (companyName: string): GridColDef[] => {
    return [
        {
            field: 'region',
            headerName: 'Territoires',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'warehouses',
            headerName: `Points de vente (DN)`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWarehouses',
            headerName: `Différence de DN`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.DN}
                    />
                )
            },
        },
        {
            field: 'products',
            headerName: `Références`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.DN}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.uvc})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVolume',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.uvc}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'ca',
            headerName: `CA A (${Units.euro})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceCa',
            headerName: `Différence de CA`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.euro}
                        tooltip={TooltipMessage.CA}
                    />
                )
            },
        },
        {
            field: 'shareVolume',
            headerName: `PDM ${companyName} A (${Units.uvc})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={`${roundIfNotZero(params.value)} ${Units.percent}`}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceShareVolume',
            headerName: `Différence de PDM en volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PDM_VOLUME}
                    />
                )
            },
        },
        {
            field: 'shareCa',
            headerName: `PDM ${companyName} A (${Units.euro})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={`${roundIfNotZero(params.value)} ${Units.percent}`}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceShareCa',
            headerName: `Différence de PDM en valeur`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PDM_VALUE}
                    />
                )
            },
        },
    ]
}


export const GBHWarehousesColumns = (companyName: string): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Points de vente',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'region',
            headerName: 'Territoires',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'products',
            headerName: `Références`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceProducts',
            headerName: `Différence de références`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.DN}
                    />
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A (${Units.uvc})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVolume',
            headerName: `Différence de volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.uvc}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'ca',
            headerName: `CA A (${Units.euro})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceCa',
            headerName: `Différence de CA`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.euro}
                        tooltip={TooltipMessage.CA}
                    />
                )
            },
        },
        {
            field: 'shareVolume',
            headerName: `PDM ${companyName} A (${Units.uvc})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={`${roundIfNotZero(params.value)} ${Units.percent}`}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceShareVolume',
            headerName: `Différence de PDM en volume`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PDM_VOLUME}
                    />
                )
            },
        },
        {
            field: 'shareCa',
            headerName: `PDM ${companyName} A (${Units.euro})`,
            flex: 1.2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={`${roundIfNotZero(params.value)} ${Units.percent}`}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceShareCa',
            headerName: `Différence de PDM en valeur`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.PDM_VALUE}
                    />
                )
            },
        },
    ]
}

export const C10AndDistriboissonsColumnsSegmentationTable = (unit: Units): GridColDef[] => {
    return [
        {
            field: 'region',
            headerName: 'Région commerciale',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'warehouses',
            headerName: `Dépôts`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.value ? (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />
                        </SecondaryText>
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'volume',
            headerName: `Volume (${unit})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <SwitchText isHeader color={BLACK} length={params.colDef.headerName.length} title={params.colDef.headerName}>
                        {params.colDef.headerName}
                    </SwitchText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                if (!params.value) return <PrimaryText>-</PrimaryText>

                return (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.value)}
                            />
                            {unit}
                        </SecondaryText>
                    </Grid>
                )
            },
            sortable: false,
        },
        {
            field: 'differenceVolume',
            headerName: `Différence de volume `,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.liter}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
    ]
}