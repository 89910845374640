import { PeriodGap } from '../global/globalTypes'
import { Histogram } from '../user/userTypes'

export interface FsProductState {
    clients: Clients
    suppliers: Suppliers
    warehouses: Warehouses | WarehousesTwoList
    products: Products | ProductsChartAndTwoList
    drillDownProducts: ProductItemFS[] | ProductItemGBH[] | ProductItemC10andDistriboissons[]
    drillDownProductsNotDistributed: ProductNotDistributedItemFS[] | ProductNotDistributedItemGBH[]
    drillDownWarehouses: WarehouseItem[] | WarehouseItemGBH[]
    drillDownWarehousesNotDistributors: WarehouseNotDistributorItem[] | WarehouseNotDistributorGBHItem[]
    drillDownClients: ClientItem[]
    drillDownPromoClients: DrillDownPromoClients
    drillDownPromoInformations: DrillDownPromoInformations
    drillDownCategories: DrillDownCategories[]
}

interface Suppliers {
    brandsList: BrandsList[],
    segmentsList: SegmentsList[],
    regionsList: RegionsList[]
    suppliersList: SupplierItemGBH[],
    suppliersHistogram: Histogram
}

export interface WarehouseNotDistributorItem { id: string; warehouse: string }
export interface WarehouseNotDistributorGBHItem { warehouseName: string, warehousePtr: number, volume: number, ca: number, products: number }
export interface ProductItemGBH {
    brand: string,
    segment: string,
    warehouses: number
    warehousesLastYear: number,
    volume: number,
    ca: number,
    differenceWarehouses: number,
    differenceVolume: number,
    differenceCa: number,
    shareVolume: number,
    shareCa: number,
    differenceShareVolume: number,
    differenceShareCa: number,
    id: string,
    product: string
}
export interface ProductItemC10andDistriboissons {
    id: string,
    productName: string
    warehouses: number
    warehousesLastYear: number
    differenceWarehouses: number,
    volume: number,
    volumeLastYear: number,
    differenceVolume: number,
    unitVolume: number
    differenceUnitVolume: number
    vmmVolume: number
    vmmVolumeLastYear: number

    differenceVmmVolume: number
    vmmUnitVolume: number
    vmmUnitVolumeLastYear: number
    differenceVmmUnitVolume: number
}

export interface SupplierItemGBH {
    id: string,
    supplier: string
    brand: string,
    segment: string,
    region: string
    warehouses: number
    warehousesLastYear: number,
    volume: number,
    ca: number,
    differenceWarehouses: number,
    differenceVolume: number,
    differenceCa: number,
    shareVolume: number,
    shareCa: number,
    differenceShareVolume: number,
    differenceShareCa: number,
}
export interface BrandsList {
    brand: string;
    differenceProducts: number;
    volume: number;
    differenceVolume: number;
    ca: number;
    differenceCa: number;
    shareVolume: number;
    differenceShareVolume: number;
    shareCa: number;
    differenceShareCa: number;
    products?: number;
    suppliers?: number
}

export interface SegmentsList {
    segment: string;
    differenceProducts: number;
    volume: number;
    differenceVolume: number;
    ca: number;
    differenceCa: number;
    shareVolume: number;
    differenceShareVolume: number;
    shareCa: number;
    differenceShareCa: number;
    products?: number;
    suppliers?: number
}

export interface RegionsList {
    region: string;
    products: number;
    differenceProducts: number;
    volume: number;
    differenceVolume: number;
    ca: number;
    differenceCa: number;
    shareVolume: number;
    differenceShareVolume: number;
    shareCa: number;
    differenceShareCa: number;
    suppliers: number
}
export interface DrillDownCategories {
    id: string
    volume: number
    volumeLastYear: number
}
export interface DrillDownPromoInformations {
    sellOutKPI: {
        clients: number
        clientsLastYear: number
        vmm: number
        vmmLastYear: number
        volume: number
        volumeLastYear: number
        warehouses: number
        warehousesLastYear: number
    }
    promoKPI: {
        investmentPerVolume: number
        investmentPerVolumeLastYear: number
    }
    sellInKPI: {
        netAveragePrice: number
        netAveragePriceLastYear: number
    }
}
export interface DrillDownPromoClients {
    list: PromoItem[]
    top3Warehouses: Top3Warehouses[]
    topCategoryPerSubSubType: TopCategory[]
}
export interface TopCategory {
    subSubType: string
    category: string
    percentage: number
}
export interface Top3Warehouses {
    subSubType: string
    warehouses: { warehouseName: string; percentage: number }[]
}
export interface PromoItem {
    id: string
    type: string
    products: number
    clients: number
    clientsSubSubType: number
    clientsSubSubTypeLastYear: number
    warehouses: number
    warehousesLastYear: number
    productsLastYear: number
    volume: number
    volumeLastYear: number
    differenceWeight: number
    differenceProducts: number
    differenceWarehouses: number
    differenceClients: number
}

export interface Product {
    id: string
    name: string
    ean: string
    promo: number
    netSellIn: number
    volSellIn: number
    volSellOut: number
    percentageVolSellIn: number
    percentageVolSellOut: number
    percentageNetSellIn: number
    percentagePromo: number
}
export interface Segmentation {
    labels: string[]
    data: number[]
    clients?: number[]
    percentage: number[]
}

export enum SegmentationType {
    TYPE = 'type',
    SUB_TYPE = 'subType',
    SUB_SUB_TYPE = 'subSubType',
}

export type ClientSegmentationFFR = {
    [key in SegmentationType]: Segmentation
}

export type ClientSegmentationTGM = {
    type: Segmentation
}
export interface ClientItem {
    type: string
    products: number
    clients: number
    volume: number
    valuesUVC: number
    volumeLastYear: number
    oldValuesUVC: number
    differenceUVC: number
    differenceWeight: number
}
export interface CategoriesItem {
    id: string
    volume: number
    volumeLastYear: number
}

export interface Clients {
    segmentation: ClientSegmentationFFR | ClientSegmentationTGM
    list: ClientItem[]
    period: PeriodGap
}

export interface WarehouseItem {
    warehouse: string
    products: number
    clients: number
    volume: number
    valuesUVC: number
    volumeLastYear: number
    oldValuesUVC: number
    differenceUVC: number
    differenceWeight: number
    ca?: number
    evolCa?: number
}
export interface WarehouseItemGBH {
    warehouse: string
    region: string
    products: number
    productsLastYear: number
    volume: number
    differenceVolume: number
    ca: number
    differenceCa: number
    shareVolume: number
    shareCa: number
    differenceShareVolume: number
    differenceShareCa: number
}

export interface Warehouses {
    list: WarehouseItem[]
    period: PeriodGap
}

export interface Chart {
    name: string
    labels: string[]
    data: number[]
    brand?: string
}

interface Legend {
    family: string
    subFamily: { label: string; products: string[] }[]
}

export type ProductsChart = {
    chart: Chart[]
    legend: Legend[]
}

export interface ProductItemFS {
    type: string
    product: number
    productName: string
    warehouses: string
    clients: number
    volume: number
    volumeLastYear: number
    oldValuesUVC: number
    differenceUVC: number
    differenceWeight: number
}

export interface ProductNotDistributedItemFS {
    productName: string
    vmm: number,
    type?: string
}
export interface ProductNotDistributedItemGBH {
    productName: string
    volume: number
    ca: number
    warehouses: number
}

export interface Products {
    chart: ProductsChart
    list: ProductItemFS[]
    period: string[]
}
export interface ProductsChartAndTwoList {
    brandsList: BrandsList[],
    segmentsList: SegmentsList[]
    productsList: ProductItemGBH[]
    chart: ProductsChartGBH
    period: string[]
}
export type ProductsChartGBH = {
    chart: Chart[]
    legend: {
        brand: string
    }[]
}
export interface WarehousesTwoList {
    regionsList: RegionItemWithShare[] | RegionItemWithLiterAndUVC[]
    warehousesList: WarehouseItemWithShare[] | WarehouseItemWithLiterAndUVC[]
}

export interface RegionItemWithShare {
    id: string
    region: string
    warehouses: number
    products: number
    volume: number
    ca: number
    differenceWarehouses: number
    differenceProducts: number
    differenceVolume: number
    differenceCa: number
    shareVolume: number
    shareCa: number
    differenceShareVolume: number
    differenceShareCa: number
}

export interface WarehouseItemWithShare {
    id: string
    warehouse: string
    region: string
    products: number
    volume: number
    ca: number
    differenceProducts: number
    differenceVolume: number
    differenceCa: number
    shareVolume: number
    shareCa: number
    differenceShareVolume: number
    differenceShareCa: number
}

export interface RegionItemWithLiterAndUVC {
    id: string
    region: string
    warehouse: number
    products: number
    differenceProducts: number
    volume: number
    differenceVolume: number
    unitVolume: number
    differenceUnitVolume: number
    vmmVolume: number
    vmmUnitVolume: number
    differenceVmmVolume: number
    differenceVmmUnitVolume: number
}

export interface WarehouseItemWithLiterAndUVC {
    id: string
    warehouse: string
    region?: string
    products: number
    differenceProducts: number
    volume: number
    differenceVolume: number
    unitVolume: number
    differenceUnitVolume: number
    vmmVolume: number
    vmmUnitVolume: number
    differenceVmmVolume: number
    differenceVmmUnitVolume: number
}